
@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('./Fonts/Rubik-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

/* General Styles */
body {
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: 'Rubik', sans-serif;
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
    flex-direction: row-reverse;
}

a {
    text-decoration: none;
}

button, input {
    outline: none;
    border: none;
}

/* Logo */
.logo img {
    height: 70px;
    display: block;
    align-self: center;
    transition: 0.4s ease-in-out;
}

/* Main Section */
#main {
    width: 100%;
    position: relative;
    height: 720px;
    background: rgb(189,42,32);
    background: linear-gradient(315deg, rgba(189,42,32,1) 0%, rgba(237,225,124,1) 100%);
    z-index: 0;
}

#main::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* Navigation */
nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
    background-color: white;
}

nav.active {
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
    width: 55vw;
    position: fixed;
    scale: 0.95;
    left: 50%;
    margin-left: -27.5%;
    border-radius: 15px;
    top: 2%;
}

nav.active .logo img {
    height: 60px;
}

nav ul {
    display: flex;
}

nav ul li a {
    font-family: 'Rubik';
    height: 40px;
    line-height: 43px;
    margin: 3px;
    padding: 0 22px;
    display: flex;
    font-size: 0.8rem;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}

nav ul li a:hover {
    cursor: pointer;
    background-color: #F3BB4A;
    color: #ffffff;
    box-shadow: 5px 10px 30px rgba(239, 209, 17, 0.336);
    transition: all ease 0.2s;
}

nav .menu-btn,
.menu-icon {
    display: none;
}

/* Active Class */
.active {
    color: #ffffff;
    box-shadow: 5px 10px 30px rgba(249, 175, 37, 0.411);
    transition: all ease 0.4s;
}

.active:hover {
    background-color: white;
    color: #ffffff;
    box-shadow: 5px 10px 30px rgba(249, 162, 56, 0.411);
    transition: all ease 0.2s;
}

/* Name Section */
.name {
    font-family: 'Rubik';
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: 5%;
}

.name span {
    color: #3C0D03;
}

.name .details {
    font-size: 1.4rem;
    color: white;
}

.name h2 {
    font-family: 'Rubik';
    font-size: 2.4rem;
    margin: 0;
    letter-spacing: 2px;
    margin-top: 15%;
    color: white;
}

.name h1 {
    font-family: 'Rubik';
    font-size: 4rem;
    margin: 0;
    letter-spacing: 2px;
    color: white;
    display: inline-block;
    margin-top: 10px;
    border-radius: 15px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: black;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

/* Header Buttons */
.header-btns {
    display: flex;
}

.header-btn {
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #ffb114;
    box-shadow: 5px 10px 30px rgba(255, 188, 2, 0.399);
    border-radius: 5px;
    margin-top: 20px;
    color: #ffffff;
    border: 2px dashed transparent;
    transition: background-color 0.7s cubic-bezier(0.4, 0.2, 0.25, 1), color 0.7s cubic-bezier(0.4, 0.2, 0.25, 1), box-shadow 0.7s cubic-bezier(0.4, 0.2, 0.25, 1), border-color 0.7s cubic-bezier(0.4, 0.2, 0.25, 1);
}

.header-btn:hover {
    cursor: pointer;
    background-color: transparent;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    color: #ffffff;
    border: 3px dashed rgba(255, 255, 255, 0.581);
}

/* Graphs Section */
.graphs {
    position: absolute;
    width: 48vw;
    margin-left: 20px;
    top: 20%;
}

/* Features Section */
#features {
    width: 100%;
    height: 120vh;
    box-sizing: border-box;
    font-family: 'Rubik';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: white;
}

#features h2 {
    margin-top: 20vh;
    margin-bottom: 80px;
    color: black;
    font-size: 2.8rem;
}

.a-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.a-box {
    background-color: #F3BB4A;
    width: 250px;
    height: 400px;
    margin: 20px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    border: 2.5px dashed black;
    transition: all 0.5s;
    text-decoration: none;
}

.a-b-img {
    width: 100%;
    height: 35%;
}

.a-b-img img {
    padding: 15px;
    width: 100%;
    height: 100%;
}

.a-box:hover {
    transform: scale(1.03);
    border: 1.5px solid gold;
    box-shadow: 2px 2px 12px rgba(231, 197, 7, 0.863);
}

.a-b-text {
    width: 100%;
    background-color: #F3BB4A;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.a-b-text h3 {
    font-size: 1.5rem;
    color: #3C0D03;
    margin-top: 15px;
    margin-bottom: 0;
}

.a-b-text p {
    color: #3C0D03;
    line-height: 1.35;
    font-size: 1.1rem;
    font-family: 'Rubik';
    display: block;
    display: -webkit-box;
    max-width: 90%;
    position: absolute;
    bottom: 15px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 120px;
}

.a-b-text a {
    margin-top: 15px;
}

/* Presentation Section */
#presentaion {
    padding-top: 35px;
    padding-bottom: 35px;
    position: relative;
    background-color: #F3BB4A;
    text-align: center;
}

.offerdiv {
    width: 40vw;
    display: flex;
    justify-self: flex-start;
    align-self: center;
}

.offerimg {
    margin: 15px 0;
    margin-left: 20px;
    width: 40vw;
    display: flex;
    justify-self: flex-start;
    align-self: center;
}

/* Project Title */
.pr-title {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.4rem;
    font-weight: bold;
    color: #3C0D03;
    text-align: center;
}

/* Project Description */
.pr-div {
    text-align: right;
    font-family: 'Rubik';
    position: absolute;
    right: 0;
    margin-right: 35px;
    top: 35%;
    color: black;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 45vw;
}

.pr-div span {
    color: #3C0D03;
    font-size: 1.45rem;
    font-weight: bold;
}

.pr-div h2 {
    font-family: 'Rubik';
    font-size: 4rem;
    margin: 0;
    letter-spacing: 2px;
    color: #ffffff;
}

/* Project Buttons */
.pr-btns {
    display: flex;
    margin-top: 40px;
    margin-left: 35%;
}

.pr-btn {
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #FF1414;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
    color: #ffffff;
}

.pr-btn:hover {
    background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #FF1414;
}

/* About Section */
#about {
    width: 100%;
    height: 100%;
    background-color: white;
}

.grid-layout {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.aboutTitle {
    text-align: center;
    font-size: 2rem;
    padding: 20px;
}

.client {
    width: 50%;
    height: 100px; /* Adjust as needed */
    display: flex;
    padding: 100px 50px;
    margin-bottom: 35px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Ensures padding and border are included in the width and height */
}


.client-img {
    width: 100%;
}

.client-imgdiv {
    position: relative;
    padding: 10px;
    padding-top: 0px;
    padding-left: 15px;
    margin-left: 5px;
}

.blogo-img {
    width: 150px;
    height: 150px;
    margin-left: 10px;
    filter: drop-shadow(7px 7px 4px rgba(0, 0, 0, 0.6)); /* Apply the drop-shadow filter */
    background-color: transparent;
}

.profile-img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.client h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1rem;
}

.client p {
    margin-top: 5px;
    margin-right: 0px;
    font-size: 1rem;
}


/* Terms of Use Section */
.terms-container {
    background-color: #f0f0f0;
    color: #333;
    padding: 20px;
    text-align: center;
    padding-top: 75px;
}

.terms-container h1 {
    color: #000;
}

.terms-container p {
    max-width: 75vw;
    margin: 0 auto;
}

/* Footer */
.footer {
    background-color: #000;
    color: #ffa500;
    padding: 20px;
}

.logo-container {
    text-align: center;
    margin-bottom: 20px;
}

.sub-text {
    color: #fff;
    font-size: 12px;
    margin-top: -10px;
}

.impact-statement {
    text-align: center;
    margin-bottom: 40px;
}

.footer-logo {
    height: 100px;
}

.footer-content {
    display: flex;
    justify-content: space-around;
}

.footer-section {
    text-align: right;
}

.footer-section h3 {
    margin-bottom: 10px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section li {
    margin-bottom: 8px;
}

.footer-section a {
    color: white;
    padding: 4px;
}

/* Contact Section */
.contact-container {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 0 50px;
}

.contact-form-box {
    background: rgb(189,42,32);
    background: linear-gradient(315deg, rgba(189,42,32,1) 0%, rgba(237, 226, 124, 0.76) 100%);
    padding: 30px 40px;
    border-radius: 20px;
    box-shadow: 4px 4px 1.2px rgb(0, 0, 0);
    border: 1.7px solid black;
    text-align: center;
    width: 550px;
    position: relative;
}

.contact-form-box h2 {
    margin-bottom: 20px;
    font-size: 2rem;
}

.progress-bar {
    width: 75%;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.107);
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.progress {
    width: 50%;
    height: 100%;
    background-color: #BD2A2A;
    transition: all 0.5s;
}

.contact-form {
    padding: 10px;
}

#contact-form1 {
    left: 450px;
}

#contact-form2 {
    left: -450px;
}

.contact-input,
.contact-textarea {
    width: 100%;
    padding: 14px;
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 15px;
    background-color: #0000000d;
    box-sizing: border-box;
    border: 2px solid #bd2a2a00;
    text-align: right;
}

::placeholder {
    color: rgba(0, 0, 0, 0.718);
}

.contact-input:focus,
.contact-textarea:focus,
.contact-input:hover,
.contact-textarea:focus {
    border: 2px solid #bd2a2aa4;
}

.contact-textarea {
    resize: none;
    outline: none;
    height: 150px;
}

.contact-textarea:focus,
.contact-textarea:hover {
    border: 2px solid #bd2a2aa4;
}

#back {
    width: 45%;
    margin-left: 5%;
}

#submit {
    width: 45%;
    margin-right: 5%;
}

.contact-button {
    background-color: #000000;
    color: #ffffff;
    padding: 10px 20px;
    margin-top: 10px;
    width: 100%;
    border: 2px solid transparent;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.contact-button:hover {
    background-color: #bd2a2a;
    border-color: #bd2a2a;
    color: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
}

.contact-button::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
}

.contact-button:hover::before {
    transform: translate(-50%, -50%) scale(1);
}

.contact-button:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-content {
    max-width: 50vw;
    font-weight: 500;
    margin-left: 15px;
}

.contact-content span {
    color: #BD2A2A;
    font-size: 40px;
}

.contact-content p {
    color: black;
    font-size: 24px;
}

.contact-hr {
    border: 0;
    height: 3.5px;
    background-color: #d92b2b;
    width: 50px;
    margin: 10px 0;
}

/* Media Queries */


@media screen and (max-width: 1190px) {

    #main {
        height: 650px;
    }


    nav {
        justify-content: center;
    }

    nav.active {
        background-color: white;
        width: 85vw;
        margin-left: -42%;
        padding: 2px;
        transition: all 0.05s ease;
        will-change: transform, opacity;
    }
    nav.active .logo img {
        height: 60px;
        transition: all 0.05s ease;
    }

    .header-heading {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .header-btn {
        margin-top: 10px;
    }    

    .name {
        margin-right: 18px;
        }

    .name h2 {
        font-size: 1.8rem;
    }

    .name h1 {
        font-size: 3.5rem;
    }

    .name .details {
        font-size: 1.3rem;
        color: white;
    }

    .graphs {
        width: 55vw;
        top: 20%;
        margin-left: 5px;
    }



    #features {
        height: auto;
    }
    .a-container {
        flex-wrap: wrap;
        margin-bottom: 5vw;
    }
    .a-box {
        flex-grow: 1;
        flex: 1 1 40%;
        height: 200px;
        height: 300px;
        margin: 25px;
    }
    .a-b-img img {
        object-fit: contain;
    }

    .a-b-text h2 {
        margin-top: 5px; 
    }

    .a-b-text p {
        font-size: 1rem;
        bottom: -50px;
    }


    .pr-title {
        font-size: 1.4rem;
    }

    #presentaion {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .offerimg {
        margin: 50px 0;
        margin-left: 20px;
        width: 40vw;
        display: flex;
        justify-self: flex-start;
        align-self: center;
    }

    .pr-div {
        top: 25%;
    }
    
    .pr-div span {
        font-size: 1.3rem;
    }

    .pr-div p {
        font-size: 1.2rem;
    }

    #about {
        height: 100%;
    }

    .aboutTitle {
        margin-top: 50px;
        text-align: center;
    }

    .grid-layout {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .blogo-img {
        margin-left: auto;
        margin-bottom: 10px;
    }

    .client {
        flex-direction: column;
        grid-template-columns: 1 0 0;
        padding: 10px;
        width: 100%;
        height: 100%;
        font-size: 0.7rem;
        padding: 15px 10px;
    }

    /* Contact Section */
    .contact-container {
        margin-top: 50px;
        padding: 0 40px;
    }
    .contact-form-box {
        padding: 18px 30px;
        width: 450px;
        height: 400px;
        box-shadow: 3px 3px 1.2px rgb(0, 0, 0);
    }
    .contact-form {
        padding: 5px;
    }
    .contact-content {
        margin-left: 15px;
    }
    
    .contact-content span {
        color: #BD2A2A;
        font-size: 1.4rem;
    }
    
    .contact-content p {
        color: black;
        font-size: 1.2rem;
    }
    
    .progress-bar {
        margin-bottom: 10px;
    }

    .contact-hr {
        border: 0;
        height: 2.5px;
        background-color: #d92b2b;
        width: 50px;
        margin: 10px 0;
    }
}

.nav-mobile {
    display: none;
   }

@media screen and (max-width: 768px) {

    .nav {
        display: none;
       }

    .nav-mobile {
        position: fixed;
        display: block;
        margin-bottom: 0px;
        background-color: white;
        height: 7.5vh;
    }

    .nav-mobile.active{
        display: block;
        width: 100vw;
        height: 7.5vh;
        left: 0;
        margin-left: 0;
        scale: 1;
        top: 0%;
        border-radius: 0px;
    }

    .checkbtn{
        font-size: 1.5rem;
        color: black;
        position: absolute;
        right: 10px;
        cursor: pointer;
        top: 50%;
        padding: 8px;
        transform: translateY(-50%)
      }

      #check{
        display: none;
      }

      #check:checked ~ .mobile-ul{display: flex;}

    .logo {
        width: fit-content;
        display: block;
    }
    
    .logo img {
        height: 50px;
        margin-left:10px;
        margin-top: 5px;
    }

    .mobile-ul {
        display: none;
        color: #000;
        margin-top: 0px;
        background-color: white;
        padding: 1rem 1.5rem;
        align-items: center;
        flex-direction: column;
    }

    .mobile-ul li {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .mobile-ul li a {
        width: 100%;
        justify-content: center;
        text-align: center;
        font-size: 1rem
    }
    

    nav.active .logo img {
        height: 50px;
        scale: 1;
        margin-left:10px;
        margin-top: 5px;
    }

    .mobile-ul a {
        color: #000;
    }

    #main{
        height: fit-content;
    }
    .name {
        text-align: center;
        width: 100%;
        margin-right: 0%;
    }

    .name h1 {
        font-size: 2.8rem;
    }

    .name h2 {
        font-size: 1.7rem;
        margin-top: 30vw;
    }

    .name .details {
        font-size: 1.4rem;
        margin-left: 15px;
        margin-right: 15px;
    }
    
    .header-btn {
        font-size: 1.4rem;
        width: 160px;
        height: 50px;
        margin: 7px;
    }

    .graphs {
        width: 95vw;
        position: relative;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    #features h2 {
        font-size: 1.6rem;
        text-align: center;
    }

    .a-box {
        width: 40px;
        height: 200px;
    }
    .a-b-text h3{
        font-size: 1.3rem;
        margin-top: -10px; 
    }
    .a-b-text p {
        font-size: 1rem;
    }

    #features h2{
        margin-top: 50px;
        margin-bottom: 10px;
    }

    #presentaion {
        height: 100vh;
        margin-top: 10px;
        padding-top: 0px;
        display: flex;
    }

    .offerdiv {
        height: 100%;
    }

    .offerimg {
        position: relative;
        min-width: 60vw;
        max-width: 75vw;
        justify-self: center;
        align-self: flex-end;
        margin: 0;
        margin-left: 15px;
    }
    .pr-div {
        margin-right: 15px;
        margin-left: 15px;
        max-width: 100vw;
    }
    .pr-title {
        font-size: 1.2rem;
        width: 80%;
    }

    .pr-div span {
        font-size: 1.3rem;
    }
    .pr-div p{
        font-size: 1.2rem;
    }

     /* Contact Section */
     .contact-container {
        padding: 20px 20px;
        flex-direction: column-reverse;
        height: fit-content;
    }

    .contact-content {
        max-width: 90vw;
    }
    .contact-form-box {
        padding: 5px 8px;
        height: fit-content;
        width: 100%;
        box-shadow: 3px 3px 1.2px rgb(0, 0, 0);
        margin-bottom: 50px;
    }

    .contact-input, .contact-textarea {
        width: 100%;
        padding: 8px;
        margin: 8px 0;
    }

    .contact-form {
        padding: 5px;
    }
    .contact-form-box h2{
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
    .contact-content {
        margin-left: 0px;
    }
    
    .contact-content span {
        color: #BD2A2A;
        font-size: 1.3rem;
    }
    
    .contact-content p {
        color: black;
        font-size: 1.2rem;
    }
    
    .progress-bar {
        margin-bottom: 5px;
    }

    .contact-hr {
        border: 0;
        height: 2px;
        background-color: #d92b2b;
        width: 50px;
        margin: 8px 0;
    }

    /* Footer */

    .footer-logo {
        height: 80px;
    }

    .impact-statement h2{
        font-size: 1.2rem;
    }

    .footer-section h3{
        font-size: 1rem;
    }
    .footer-section li {
        margin-bottom: 2px;
        font-size: 0.9rem;
    }
}

/*768 400*/
